const elements = {
  sky: document.querySelector(".sky"),
  land: document.querySelector(".land"),
  sun: document.querySelector('.sun')
}

const generateStars = (num) => {
  for (let i = 0; i < num; i++) {
    const star = document.createElement('div')
    const size = Math.random() * 3 + 'px';
    star.classList.add('star')
    star.style.top = Math.ceil((Math.random() * 100) / 2) + 'vh'
    star.style.left = Math.ceil(Math.random() * 100) + 'vw';
    star.style.animationDuration = Math.random() + 1 * Math.random() + 's';
    star.style.height = size;
    star.style.width = size;
    elements.sky.appendChild(star)
  }
}
const generateRocks = (num) => {
  for (let i = 0; i < num; i++) {
    const rock = document.createElement("div");
    const size = Math.random() * 5 + "px";
    rock.classList.add("rock");
    rock.style.top = Math.ceil((Math.random() * 10) * 5) + "vh";
    rock.style.left = Math.ceil(Math.random() * 100) + "vw";
    rock.style.height = size;
    rock.style.width = size;
    elements.land.appendChild(rock);
  }
}

export { generateStars, generateRocks, elements as default }